import React, { useContext } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import * as styles from "./home.module.scss"
import { globalStateContext } from "../context/GlobalContextProvider"
import CardText from "../components/common/CardText"

const IndexPage = ({ data }) => {
  const banner = getImage(data.banner.childImageSharp.gatsbyImageData)
  const logo = getImage(data.logo.childImageSharp.gatsbyImageData)
  const textData = data.textData
  const allImages = data.allImages.nodes

  const state = useContext(globalStateContext)
  const { language } = state
  const isEnglish = language === "EN"

  return (
    <Layout>
      <Seo title="Home" />
      <main className={styles.container}>
        <section className={styles.hero}>
          <div className={styles.heroText}>
            <GatsbyImage image={logo} alt="logo" className={styles.logo} />
            <h1>{isEnglish ? textData.hero[0] : textData.hero[1]}</h1>
            <p>{isEnglish ? textData.subHero[0] : textData.subHero[1]}</p>
            <br />
            <br />
            <br />
            <p className={styles.extra}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              vel qui eaque sunt, corporis eos! Cumque, deserunt. Maiores fugiat
              amet obcaecati cum delectus reiciendis ducimus corrupti laborum
              iure odit sint rerum officia magni alias cupiditate enim
              inventore, esse quae vero, numquam sit eos incidunt. Facilis magni
              itaque animi delectus quidem!
            </p>
          </div>
          <GatsbyImage image={banner} alt="banner" className={styles.banner} />
        </section>
        <section>
          {allImages.map((image, index) => {
            const isEven = index % 2 === 0
            return (
              <section className={styles.articles} key={index}>
                <CardText
                  text={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, necessitatibus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam doloribus repellat facere error ab earum eligendi! Exercitationem maiores nemo veniam."
                  }
                  image={
                    <GatsbyImage
                      image={image.childImageSharp.gatsbyImageData}
                      alt="projects"
                    />
                  }
                  isEven={isEven}
                />
              </section>
            )
          })}
        </section>
      </main>
    </Layout>
  )
}
export const query = graphql`
  query data {
    banner: file(relativePath: { eq: "main-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    logo: file(relativePath: { eq: "anico-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    textData: homeJson {
      hero
      subHero
      title
    }
    allImages: allFile(filter: { relativeDirectory: { eq: "home" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
export default IndexPage
