import React from "react"
import * as styles from "./cardText.module.scss"
const CardText = ({ text, image, isEven }) => {
  return (
    <>
      <aside>title</aside>
      <article className={`${styles.container} ${isEven && styles.even}`}>
        <div className={styles.text}>{text}</div>
        <div className={styles.image}>{image}</div>
      </article>
    </>
  )
}

export default CardText
